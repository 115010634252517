<script setup lang="ts">
import { useQuery } from '@tanstack/vue-query'
import { storeToRefs } from 'pinia'
import { useJobApplicationStore } from './job-applications/job-application-store'
import { useEmployerApi } from '@/modules/http-clients'
import VueQueryWrapper from '@/components/VueQueryWrapper.vue'

definePage({
  name: '/job-applications.[jobApplicationId]',
  meta: {
    permissionResource: 'JobApplication',
  },
})

const route = useRoute('/job-applications.[jobApplicationId]')
const jobApplicationId = Number.parseInt(route.params.jobApplicationId)

const employerApi = useEmployerApi()
const { data, error, isPending } = useQuery({
  queryKey: ['job-application-detail', jobApplicationId],
  queryFn: async () => {
    const result = await employerApi.getJobApplication(jobApplicationId)
    const jobApplicationStore = useJobApplicationStore()
    const { isCompleteProfileRequestSent } = storeToRefs(jobApplicationStore)
    isCompleteProfileRequestSent.value = result.isCompleteProfileRequestSent
    return result
  },
})
</script>

<template>
  <VueQueryWrapper :is-pending="isPending" :error="error">
    <template #isPending>
      <Skeleton height="20rem" />
    </template>
    <JobApplicationDetail v-if="data" :job-application="data" />
  </VueQueryWrapper>
</template>
