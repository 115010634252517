/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the SendCompleteProfileRequestCommandDto interface.
 */
export function instanceOfSendCompleteProfileRequestCommandDto(value) {
    if (!('userId' in value) || value['userId'] === undefined)
        return false;
    return true;
}
export function SendCompleteProfileRequestCommandDtoFromJSON(json) {
    return SendCompleteProfileRequestCommandDtoFromJSONTyped(json, false);
}
export function SendCompleteProfileRequestCommandDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'userId': json['userId'],
    };
}
export function SendCompleteProfileRequestCommandDtoToJSON(json) {
    return SendCompleteProfileRequestCommandDtoToJSONTyped(json, false);
}
export function SendCompleteProfileRequestCommandDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'userId': value['userId'],
    };
}
