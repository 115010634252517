<script setup lang="ts">
import thumbnail from '@/assets/thumbnail-app.webp'
import { useResponsive } from '@/modules/responsive'

const responsive = useResponsive()
</script>

<template>
  <div class="h-100dvh flex grow flex-col items-center gap-y-10 py-5">
    <img
      :src="thumbnail" alt="Logo"
      class="item-container rounded-lg object-cover shadow-md"
      :class="{
        'h-50': responsive.isMobile,
        'h-xs md:h-65 xl:h-50': responsive.isDesktop,
      }"
    >

    <section class="item-container">
      <slot>
        <router-view v-slot="{ Component }">
          <suspense>
            <component :is="Component" />
          </suspense>
        </router-view>
      </slot>
    </section>

    <section class="flex flex-auto flex-col items-center flex-justify-end">
      <AppFooter class="mt-0! pt-0!" />
    </section>
  </div>
</template>

<style scoped lang="scss">
.item-container {
  @apply w-sm sm:w-lg lg:w-md;
}
</style>
