import type { AccountInfo, AuthenticationResult, ExternalTokenResponse, SilentRequest } from '@azure/msal-browser'
import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser'
import type { JwtPayload } from 'jwt-decode'
import jwt_decode from 'jwt-decode'
import { AuthProvider, AuthService } from '@/services/auth/auth-service'
import { AuthState } from '@/store/auth'

/**
 * MSAL authentication service.
 */
export class MsalAuthService extends AuthService<AccountInfo, PublicClientApplication> {
  authProvider = AuthProvider.Msal

  private scopes = ['openid', import.meta.env.VITE_AZURE_AD_SCOPE_API || '']

  /**
   * @inheritDoc
   */
  protected user = () => this.authClient.getActiveAccount()

  constructor() {
    const clientApplication = new PublicClientApplication({
      auth: {
        clientId: import.meta.env.VITE_AZURE_AD_CLIENT_ID || '',
        authority: import.meta.env.VITE_AZURE_AD_AUTHORITY,
        redirectUri: import.meta.env.VITE_AZURE_AD_REDIRECT_URI || '',
        postLogoutRedirectUri: import.meta.env.VITE_AZURE_AD_POST_LOGOUT_REDIRECT_URI || '',
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
      },
    })

    super(clientApplication)
  }

  /**
   * @inheritDoc
   */
  async init() {
    await this.authClient.initialize()

    if (this.user() && this.accessToken) {
      return
    }

    await this.authClient.handleRedirectPromise()
    await this.handleSetAccount()
  }

  /**
   * @inheritDoc
   */
  async isAuthenticated() {
    return !!this.user()
  }

  async getAccessToken() {
    const user = this.user()
    if (!user) {
      return null
    }
    const request = {
      scopes: this.scopes,
      authority: import.meta.env.VITE_AZURE_AD_AUTHORITY,
      account: user,
      redirectUri: `${window.location.origin}/auth/redirect`,
    }
    return this.authClient.acquireTokenSilent(request)
      .catch(async (error: unknown) => {
        if (error instanceof InteractionRequiredAuthError) {
          return this.authClient.acquireTokenPopup(request)
        }
        throw error
      }).then((result: AuthenticationResult) => {
        return result.accessToken
      })
  }

  /**
   * @inheritDoc
   */
  async logout() {
    if (!this.user()) {
      return false
    }
    // clear local storage instead of logoutRedirect, otherwise user will sign out from all apps
    // await this.authClient.logoutRedirect()

    localStorage.clear()

    this.accessToken = null

    return true
  }

  private async handleSetAccount() {
    const accounts = this.authClient.getAllAccounts()
    if (accounts.length > 0) {
      this.authClient.setActiveAccount(accounts[accounts.length - 1])
    }

    this.accessToken = null

    await this.getAccessToken()
  }

  async loginWithToken(token: string): Promise<boolean> {
    if (this.user()) {
      return true
    }

    const validationResult = MsalAuthService.validateToken(token)
    if (validationResult !== true) {
      return false
    }

    const silentRequest: SilentRequest = {
      scopes: this.scopes,
      authority: import.meta.env.VITE_AZURE_AD_AUTHORITY,
    }

    const serverResponse: ExternalTokenResponse = {
      id_token: token,
      client_info: '',
    }

    this.authClient.getTokenCache().loadExternalTokens(
      silentRequest,
      serverResponse,
      {},
    )

    await this.handleSetAccount()

    return true
  }

  static validateToken(token: string): AuthState | true {
    try {
      jwt_decode<Partial<JwtPayload>>(token)
    } catch (error) {
      return AuthState.InvalidToken
    }

    return true
  }
}
